import React, { useState, useEffect } from "react"

export default function Img({
  className = "gatsby-image-wrapper",
  style = {},
  file = { url: "" },
  params = `?w=1920&q=80`,
  ...imgProps
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  const src = `${file.url}${params}`

  const handleLoad = () => {
    setIsLoaded(true)
  }

  useEffect(() => {
    const img = new Image()
    img.src = src

    if (img.complete) {
      handleLoad()
    }

    return () => {
      img.onload = null
    }
  }, [src])

  return (
    <div className={className} style={style}>
      <img
        src={src}
        style={{
          opacity: isLoaded ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
        onLoad={handleLoad}
        {...imgProps}
      />
    </div>
  )
}
