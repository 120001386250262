// Dependencies
import React from "react"
// Components
import Cursor from "../components/Cursor"
import Header from "../components/Header"
import Information from "../components/Information"
import Splash from "../components/Splash"
import Meta from "../components/Meta"
import ProjectBrowser from "../components/project/ProjectBrowser"
import ProjectBrowserStore from "../components/project/ProjectBrowserStore"
// CSS
import "../css/app.css"

const Layout = (props) => {
  const { pageContext, children } = props
  const { title, slug, description, image, isInformation = false } = pageContext

  return (
    <ProjectBrowserStore>
      <main>
        <Meta title={title} description={description} shareImage={image} />
        <Splash />
        <Header projectSlug={slug} isInformation={isInformation} />
        <ProjectBrowser slug={slug} />
        <Information show={isInformation} />
        {children}
        <Cursor color={isInformation ? "#EFECE6" : null} />
      </main>
    </ProjectBrowserStore>
  )
}

export default Layout
