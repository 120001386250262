/* eslint-disable */
// Dependencies
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react"
import { navigate } from "gatsby"
import { useInView } from "react-intersection-observer"
// Components
import Markdown from "../../components/Markdown"
// Sub-components
import ProjectGallery from "./ProjectGallery"
import {
  ProjectInfoContext,
  ProjectInfoActionsContext,
} from "./ProjectBrowserStore"

/*
// --------------------------------
// Project
*/

const Project = (props) => {
  // Props
  const { slug, index, images, highlightProject, currentProject } = props

  // Data
  const isCurrentProject = currentProject && currentProject.slug === slug

  // State
  const showInfo = useContext(ProjectInfoContext)
  const setShowInfo = useContext(ProjectInfoActionsContext)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  // Observers
  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  useEffect(
    useCallback(() => {
      if (inView) {
        highlightProject(index)
      }
    }, [inView, highlightProject, index]),
    [inView]
  )

  // UI
  const galleryRef = useRef()

  const handleProjectClick = () => {
    if (!isCurrentProject) {
      navigate(slug)
    }
  }

  return (
    <section
      className={`prel fs ${isCurrentProject ? "is-active" : ""}`}
      onClick={handleProjectClick}
      ref={ref}
      style={{
        backgroundColor: showInfo ? "#EFECE6" : "white",
        transition: "background-color 0.75s",
      }}
    >
      <div
        className="prel fs"
        style={{
          opacity: !showInfo ? 1 : 0,
          transform: !showInfo
            ? `translateY(0) scale(1)`
            : `translateY(-10%) scale(0.8)`,
          transition: "0.3s",
          transitionDelay: !showInfo ? "0.2s" : "0s",
        }}
      >
        <ProjectGallery
          galleryRef={galleryRef}
          projectSlug={slug}
          images={images}
          currentProject={currentProject}
          isCurrentProject={isCurrentProject}
          trackIndex={[currentSlideIndex, setCurrentSlideIndex]}
          setShowInfo={setShowInfo}
        />
      </div>

      {isCurrentProject && (
        <ProjectInfo
          {...currentProject}
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          galleryRef={galleryRef}
        />
      )}
    </section>
  )
}

/*
// --------------------------------
// ProjectInfo — slides up when click on 'Read More'
*/

const ProjectInfo = ({
  title,
  details,
  description,
  thumbnails,
  showInfo,
  setShowInfo,
  galleryRef,
}) => {
  const navigateToSlide = (index) => {
    galleryRef.current.scrollLeft = galleryRef.current.offsetWidth * index
    setShowInfo(false)
  }

  return (
    <div
      className="project-info pabs padx2 has-links"
      style={{
        height: "100%",
        transition: showInfo ? "transform 1s" : "transform 0.6s",
        top: "100%",
        transform: showInfo ? "translateY(-100%)" : "translateY(-4rem)",
      }}
    >
      <div
        className="project-info-thumbnails outerx4"
        style={{
          opacity: showInfo ? 1 : 0,
          transition: "opacity 0.75s",
        }}
      >
        {thumbnails.map((asset, index) => {
          const assetUrl = asset?.file?.url
          const src = `${assetUrl}?w=1920&q=80`
          return (
            <div
              className="thumbnail"
              onClick={() => navigateToSlide(index)}
              key={`thumb-${index}`}
            >
              <img src={src} />
            </div>
          )
        })}
      </div>
      <div
        className="project-footer pabs padx2"
        style={{
          opacity: showInfo ? 0 : 1,
          transition: "opacity 0.2s",
        }}
      >
        <span className="inlb mr">{title}</span>
        <button className="link cta" onClick={() => setShowInfo(true)}>
          Read more
        </button>
      </div>
      <div
        className={`copy-grid project-info-copy ${showInfo ? "show" : "hide"}`}
      >
        <div className="aside">
          {title}
          <Markdown field={details} className="changes" />
        </div>
        <div className="body">
          <Markdown field={description} className="changes" />
        </div>
      </div>
    </div>
  )
}

export default Project
