// Dependencies
import React, { useContext, useState, useEffect } from "react"
// Hooks
import useMouseWithSession from "../hooks/useMouseWithSession"
import useTouch from "../hooks/useTouch"
// Components
import { ProjectCursorContext } from "../components/project/ProjectBrowserStore"

const STYLES = {
  larr: {
    width: 0,
    height: 0,
    borderTop: "0.75ex solid transparent",
    borderRight: "1.5ex solid #B2ACA7",
    borderBottom: "0.75ex solid transparent",
    borderRadius: 0,
    backgroundColor: "transparent",
  },
  rarr: {
    width: 0,
    height: 0,
    borderTop: "0.75ex solid transparent",
    borderLeft: "1.5ex solid #B2ACA7",
    borderBottom: "0.75ex solid transparent",
    borderRadius: 0,
    backgroundColor: "transparent",
  },
}

const Cursor = ({ color }) => {
  const cursorStyle = useContext(ProjectCursorContext)
  const pos = useMouseWithSession()
  const [isTouch] = useTouch()
  const [scale, setScale] = useState(1)

  useEffect(() => {
    setScale(cursorStyle === "pointer" ? 1.2 : 1)
  }, [cursorStyle])

  const positioning = {
    transform: `translate3d(${pos.x}px, ${pos.y}px, 0) scale(${scale})`,
    backgroundColor: color,
  }

  return (
    !isTouch && (
      <div
        className="pfix cursor"
        style={{
          ...positioning,
          ...STYLES[cursorStyle],
        }}
      />
    )
  )
}

export default Cursor
