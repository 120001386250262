// Dependencies
import React, { useState, useEffect, useCallback } from "react"
import { useLocation } from "@reach/router"

/*
// --------------------------------
// Context Store

- This means we can update the <Header /> when we are in a project view.
- We will be passing the slug to the <ProjectBrowser /> when we are on a project page
- setCurrentProject will be invoked when there is a project
*/

export const ProjectInfoContext = React.createContext();
export const ProjectInfoActionsContext = React.createContext();

export const ProjectCursorContext = React.createContext();
export const ProjectCursorActionsContext = React.createContext();
const CURSORS = [
  "auto",
  "pointer",
  "larr",
  "rarr"
];

const ProjectBrowserStore = ({ children }) => {
  const [showProjectInfo, setShowProjectInfo] = useState(false);
  const [cursorStyle, setCursorStyle] = useState(CURSORS[0]);

  // If pressing back button while looking at project info in project...
  const location = useLocation();
  useEffect(useCallback(() => {
    if (location.pathname === '/' && showProjectInfo) {
      setShowProjectInfo(false)
    }
  }, [location, showProjectInfo]), [location])

  return (
    <ProjectInfoContext.Provider value={showProjectInfo}>
      <ProjectInfoActionsContext.Provider value={setShowProjectInfo}>
        <ProjectCursorContext.Provider value={cursorStyle}>
          <ProjectCursorActionsContext.Provider value={setCursorStyle}>
            {children}
          </ProjectCursorActionsContext.Provider>
        </ProjectCursorContext.Provider>
      </ProjectInfoActionsContext.Provider>
    </ProjectInfoContext.Provider>
  )
}

export default ProjectBrowserStore
