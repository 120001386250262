import { useState, useCallback } from 'react';
import useEventListener from './useEventListener';
import useSessionStorage from './useSessionStorage';

export default function useMouseWithSession() {

  const initialCoords = {
    x: 0,
    y: 0
  }
  const [position, setPosition] = useSessionStorage('mouse', initialCoords)
  const [coords, setCoords] = useState(position || initialCoords)

  const handler = useCallback(
    ({ clientX, clientY }) => {
      // Update coordinates
      setCoords({ x: clientX, y: clientY });
      setPosition({ x: clientX, y: clientY });
    },
    [setCoords, setPosition]
  );

  useEventListener('mousemove', handler)

  return coords
}
