/* eslint-disable */
// Dependencies
import React, { useState } from "react"
import { Link } from "gatsby"

const ProjectsIndex = ({ entries, activeIndex, scrollContainer }) => {
  // State
  const [showList, setShowList] = useState(false)
  const [projectList, setProjectList] = useState(entries)

  // UI
  const previewProject = (slug) => {
    const index = entries.findIndex((entry) => entry.slug === slug)
    scrollContainer.current.scrollTop = window.innerHeight * index
  }

  const handleMouseEnter = () => {
    setTimeout(() => {
      const list = entries.filter((entry, i) => activeIndex !== i)
      setProjectList([...list, entries[activeIndex]])
      setShowList(true)
    }, 50)
  }

  const handleMouseLeave = () => {
    setProjectList(entries)
    setShowList(false)
  }

  return (
    <div
      className="pfix b0 padx2 has-links project-footer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {projectList.map((node, index) =>
        showList ? (
          <Link
            to={`/${node.slug}`}
            className="bl link"
            onMouseEnter={() => previewProject(node.slug)}
            onClick={() => setShowList(false)}
            key={`index-item-${index}`}
          >
            {node.title}
          </Link>
        ) : activeIndex === index ? (
          <div className="bl link" key={`index-item-${index}`}>
            {node.title}
          </div>
        ) : null
      )}
    </div>
  )
}

export default ProjectsIndex
