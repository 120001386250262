import { graphql, useStaticQuery } from "gatsby"

export default function () {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          defaultTitle: title
          defaultDescription: description
          defaultShareImage: shareImage {
            file {
              url
            }
          }
          splashImages {
            file {
              url
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
