// Dependencies
import React, { useState, useEffect, useLayoutEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import PropTypes from "prop-types";
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Markdown from "../components/Markdown"

function useContentfulInformation() {
  const data = useStaticQuery(graphql`
    {
      allContentfulInformation {
        nodes {
          title
          contactDetails {
            childMarkdownRemark {
              html
            }
          }
          about {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulInformation.nodes[0]
}

const Information = ({ show }) => {
  // Data
  const { contactDetails, about } = useContentfulInformation()

  // UI
  const [shouldRender, setRender] = useState(show)

  useEffect(() => {
    if (show) {
      setRender(true)
    }
  }, [show])

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false)
    }
  }

  return (
    shouldRender && (
      <div
        className={`information-page pfix padx2 fader ${show ? "in" : "out"}`}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="copy-grid has-links mbx8">
          <Markdown className="aside" field={contactDetails} />
          <Markdown className="body" field={about} />
        </div>
      </div>
    )
  )
}

export default Information
