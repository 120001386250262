// Dependencies
import React, { useContext } from "react"
import { Link } from "gatsby"
// Components
import {
  ProjectInfoContext,
  ProjectInfoActionsContext,
} from "../components/project/ProjectBrowserStore"

const Header = ({ isInformation, projectSlug }) => {
  const currentProjectSlug = projectSlug
  const showProjectInfo = useContext(ProjectInfoContext)
  const setShowProjectInfo = useContext(ProjectInfoActionsContext)

  return (
    <header
      className="pfix has-links outerx2 guttersx2"
      style={{
        transform:
          currentProjectSlug && showProjectInfo
            ? `translateY(-3rem)`
            : currentProjectSlug
              ? `translateY(-1.5rem)`
              : `translate(0rem)`,
      }}
    >
      <div
        style={{
          opacity: !currentProjectSlug ? 1 : 0,
        }}
      >
        <Link to="/" className={`mr ${!isInformation ? "active" : ""}`}>
          Work
        </Link>
        <Link
          to="/information"
          className={`mr ${isInformation ? "active" : ""}`}
        >
          Information
        </Link>
      </div>
      <div
        style={{
          opacity: currentProjectSlug && !showProjectInfo ? 1 : 0,
        }}
      >
        <Link to="/">Back</Link>
        {/* <button className="link" onClick={handleBackLink}>Back</button> */}
      </div>
      <div
        style={{
          opacity: showProjectInfo ? 1 : 0,
        }}
      >
        <button className="link" onClick={() => setShowProjectInfo(false)}>
          Images
        </button>
      </div>
    </header>
  )
}

export default Header
