const React = require("react")
const Gatsby = require("gatsby")
const Layout = require("./src/components/Layout").default
const smoothscroll = require("smoothscroll-polyfill")

// Polyfill to ensure smooth scrolling behaviour in Safari (https://github.com/iamdustan/smoothscroll)
smoothscroll.polyfill();

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  if (props.custom404) {
    Gatsby.navigate('/information')
  }
  return <Layout {...props}>{element}</Layout>
}

exports.onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}
